<template>
  <v-container>
    <v-row class="d-flex align-center justify-center header">
      <v-col cols="auto" v-if="api.loading" class="pb-10">
        <h2 class="header header-span mb-8">Verfiying email address...</h2>
        <v-progress-circular
            indeterminate
            color="primary"
        />
      </v-col>
      <v-col vif="!api.loading && verified">
        <h5>Thank you for validating your email address.</h5>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Http from '@/api/Http';

export default {
  name: 'EmailConfirm',
  data () {
    return {
      loading: true,
      api: Http.use('data'),
      verified: false
    }
  },
  created() {
    this.api.get('/auth/verifyEmail/' + this.$route.params.id)
        .then(() => {
          this.verified = true;
        })
        .catch(err => {
          console.log(err);
        })
  }
}
</script>

<style scoped>
.header {
  color: var(--v-primary-base);
  text-align: center;
  line-height: 100%;
}
</style>
